<template>
  <div class="layout-header">
    <div class="global-header">
      <div style="flex: 1 1 0%"></div>
      <div class="global-header-right">
        <div class="global-header-right-action dropdown">
          <el-menu
            mode="horizontal"
            background-color="#545c64"
            text-color="#fff"
            active-text-color="#fff"
          >
            <el-submenu index="1">
              <template slot="title">
                <span class="subtitle">更多功能</span>
              </template>
              <el-submenu index="1-1">
                <template slot="title">文档资料</template>
                <el-menu-item index="1-1-1">
                  <a
                    class="text-white"
                    href="https://info.shihaishibiao.com/doc/5e5276fc30ab2d843859555f"
                    target="_blank"
                    >标签法规</a
                  >
                </el-menu-item>
                <el-menu-item index="1-1-2">
                  <a
                    class="text-white"
                    href="https://info.shihaishibiao.com/doc/5e529f1d5c8e9e54a49d91b7"
                    target="_blank"
                    >公告复函</a
                  >
                </el-menu-item>
                <el-menu-item index="1-1-3">
                  <a
                    class="text-white"
                    href="https://info.shihaishibiao.com/doc/5e537ca1b92be95ff484d81a"
                    target="_blank"
                    >相关标准</a
                  >
                </el-menu-item>
                <el-menu-item index="1-1-3">
                  <a
                    class="text-white"
                    href="https://info.shihaishibiao.com/doc/5e537ca1b92be95ff484d81a"
                    target="_blank"
                    >相关标准</a
                  >
                </el-menu-item>
                <el-menu-item index="1-1-4">
                  <a
                    class="text-white"
                    href="https://info.shihaishibiao.com/qa/all"
                    target="_blank"
                    >常见问题</a
                  >
                </el-menu-item>
              </el-submenu>
              <el-submenu index="1-3">
                <template slot="title">实用工具</template>
                <el-menu-item index="1-3-1">
                  <a
                    href="https://info.shihaishibiao.com/tool/nrv"
                    class="text-white"
                    target="_blank"
                    >营养素参考值（NRV）计算</a
                  >
                </el-menu-item>
                <el-menu-item index="1-3-2">
                  <a
                    href="https://info.shihaishibiao.com/tool/energy"
                    class="text-white"
                    target="_blank"
                    >能量值计算</a
                  >
                </el-menu-item>
              </el-submenu>
              <el-menu-item index="1-4">
                <a
                  href="https://info.shihaishibiao.com/lesson"
                  class="text-white"
                  target="_blank"
                  >网络课堂</a
                >
              </el-menu-item>
              <el-menu-item index="1-5">
                <a
                  href="https://www.shihaishibiao.com/docs/contactus.html"
                  class="text-white"
                  target="_blank"
                  >关于我们</a
                >
              </el-menu-item>
            </el-submenu>
          </el-menu>
        </div>

        <div class="global-header-right-action" @click="onAction('/msg')">
          <el-badge :value="parseInt(user.unReadMessage) || ''" type="danger">
            <span>我的消息</span>
          </el-badge>
        </div>
        <div
          class="global-header-right-action"
          @click="onAction('https://www.shihaishibiao.com/docs/', '_blank')"
        >
          <el-tooltip effect="light" content="帮助中心">
            <span>查看帮助</span>
          </el-tooltip>
        </div>
        <div class="global-header-right-action" @click="onAction('/train')">
          <span> 培训服务 </span>
        </div>

        <div class="global-header-right-action dropdown">
          <el-dropdown style="color: inherit" @command="onAction">
            <div class="global-header-right-action">
              <el-avatar :size="24" :src="user.headimgurl" />
              <span class="margin-left-xs">{{ user.nickname }}</span>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="/usermod">
                <i class="el-icon-s-tools" />账号设置
              </el-dropdown-item>
              <el-dropdown-item command="outLogin" divided>
                <i class="el-icon-s-release" />退出登录
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// 培训服务 、 更多、
export default {
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    async outLogin() {
      await this.$api.users_put();
      localStorage.setItem("user", JSON.stringify(this.user));
      this.$store.commit("set_user", "");
      this.$store.commit("set_enterprise", "");
      location.href = "/";
    },
    onAction(to, target) {
      if (to === "outLogin") return this.outLogin();
      if (target) return window.open(to, target);
      return this.$router.push(to);
    },
  },
};
</script>
<style lang="scss" scoped>
.layout-header {
  padding: 0px;
  height: 100%;
  width: 100%;
  z-index: 19;
  transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  color: #fff;
  flex: 0 0 auto;
  font-size: 14px;
  .global-header {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 16px;
    // background: #fff;
    // box-shadow: 0 1px 4px rgb(0 21 41 / 8%);

    .global-header-right {
      display: flex;
      float: right;
      height: 48px;
      margin-left: auto;
      overflow: hidden;
      .global-header-right-action {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 12px;
        cursor: pointer;
        transition: all 0.3s;
        .icon {
          display: inline-block;
          color: inherit;
          font-style: normal;
          line-height: 0;
          text-align: center;
          text-transform: none;
          text-rendering: optimizeLegibility;
          vertical-align: middle;
          font-size: 17px;
        }
      }
      .global-header-right-action:hover {
        background-color: rgba(0, 0, 0, 0.025);
      }
      .dropdown {
        padding: 0;
      }
    }
  }
}
</style>