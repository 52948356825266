import { render, staticRenderFns } from "./nav_left.vue?vue&type=template&id=b048aaa8&scoped=true&"
var script = {}
import style0 from "./nav_left.vue?vue&type=style&index=0&id=b048aaa8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b048aaa8",
  null
  
)

export default component.exports