<template>
  <div class="app_tags_view">
    <div class="tags_box">
      <el-menu
        :default-active="$store.getters.set_route.meta.id"
        class="el-menu-vertical-demo"
        :collapse="isCollapse"
      >
        <el-menu-item @click="left_nav" style="background: rgb(51, 51, 51)">
          <i class="iconfont iconquanbu"></i>
        </el-menu-item>
        <router-link to="/index">
          <el-menu-item
            index="1"
            @click="MtaH5.clickStat('nav', { index: 'true' })"
          >
            <i class="iconfont iconzhuye"></i>
            <span slot="title">首页</span>
          </el-menu-item>
        </router-link>
        <router-link to="/company">
          <el-menu-item
            index="2"
            @click="MtaH5.clickStat('nav', { company: 'true' })"
          >
            <i class="iconfont iconqiyexinxi"></i>
            <span slot="title">企业信息</span>
          </el-menu-item>
        </router-link>
        <router-link to="/food">
          <el-menu-item
            index="3"
            @click="MtaH5.clickStat('nav', { food: 'true' })"
          >
            <i class="iconfont iconshipinbiaoqian"></i>
            <span slot="title">订单管理</span>
          </el-menu-item>
        </router-link>
        <router-link to="/money">
          <el-menu-item
            index="4"
            @click="MtaH5.clickStat('nav', { money: 'true' })"
          >
            <i class="iconfont iconfeiyongguanli"></i>
            <span slot="title">账户管理</span>
          </el-menu-item>
        </router-link>
        <router-link to="/bill">
          <el-menu-item
            index="5"
            @click="MtaH5.clickStat('nav', { bill: 'true' })"
          >
            <i class="iconfont iconfapiaoxinxi"></i>
            <span slot="title">发票管理</span>
          </el-menu-item>
        </router-link>
        <router-link to="/couponsList">
          <el-menu-item
            index="6"
            @click="MtaH5.clickStat('nav', { coupons: 'true' })"
          >
            <i class="iconfont iconfapiaoguanli"></i>
            <span slot="title">优惠券</span>
          </el-menu-item>
        </router-link>
        <router-link to="/share">
          <el-menu-item
            index="0"
            @click="MtaH5.clickStat('share', { bill: 'true' })"
          >
            <i class="iconfont iconicon-test"></i>
            <span slot="title">邀请朋友</span>
          </el-menu-item>
        </router-link>
      </el-menu>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["user"]),
  },
  data() {
    return {
      isCollapse: true,
    };
  },
  methods: {
    left_nav() {
      this.isCollapse = !this.isCollapse;
      this.$emit("nav_state", !this.isCollapse);
    },
  },
};
</script>

<style lang="scss" scoped>
$yellow:rgb(242, 200, 17);
.el-menu {
  background: rgb(33, 33, 33);
}

.el-menu-item:focus,
.el-menu-item:hover {
  background-color: rgb(68, 68, 68);
}

.el-menu-item {
  color: #ffffff;
}

.el-menu-item i {
  color: #ffffff;
  padding-right: 10px;
}

.el-menu-item.is-active {
  background: #000;

  i {
    color: $yellow;
  }

  &:before {
    content: "";
    position: absolute;
    width: 3px;
    left: 0;
    height: 100%;
    background: $yellow;
  }
}

.app_tags_view {
  .tags_box {
    position: fixed;
    left: 0;
    bottom: 0;
    top: 65px;
  }
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 164px;
    height: 100%;
  }
  .el-menu--collapse {
    height: 100%;
  }
}
</style>
