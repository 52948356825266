<template>
  <div class="app_nav_bar">
    <div class="nav_bar_wrap">
      <NavLeft />
      <div class="nav_right">
        <NavRight />
      </div>
    </div>
  </div>
</template>

<script>
import NavLeft from "./nav_left";
import NavRight from "./NavRight";
export default {
  components: {
    NavLeft,
    NavRight
  }
};
</script>

<style lang="scss" scoped>
.app_nav_bar {
  .nav_bar_wrap {
    background: #000;
    display: flex;
    .nav_right {
      flex: 1;
      height: 65px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}
</style>
