import moment from 'moment';
export function isvalidUsername(str) {
    const valid_map = ['admin', 'editor'];
    return valid_map.indexOf(str.trim()) >= 0;
}

/* 合法uri*/
export function validateURL(textval) {
    const urlregex = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
    return urlregex.test(textval);
}

/* 小写字母*/
export function validateLowerCase(str) {
    const reg = /^[a-z]+$/;
    return reg.test(str);
}

/* 大写字母*/
export function validateUpperCase(str) {
    const reg = /^[A-Z]+$/;
    return reg.test(str);
}

/* 大小写字母*/
export function validatAlphabets(str) {
    const reg = /^[A-Za-z]+$/;
    return reg.test(str);
}
/**
 * 判断是否为空
 */
export function validatenull(val) {
    if (typeof val === 'boolean') {
        return false;
    }
    if (val instanceof Array) {
        if (val.length === 0) return true;
    } else if (val instanceof Object) {
        if (JSON.stringify(val) === '{}') return true;
    } else {
        if (val === 'null' || val == null || val === 'undefined' || val === undefined || val === '') return true;
        return false;
    }
    return false;
}

/**
 * 判断手机号码是否正确
 */
export function isvalidatemobile(phone) {
    const list = [];
    let result = true;
    let msg = '';
    let mobileRegex = /^[1]([3-9])[0-9]{9}$/;
    if (!validatenull(phone)) {
        if (phone.length === 11) {
            if (mobileRegex.test(phone)) {
                return true;
            } else {
                msg = '手机号码格式不正确';
            }
        } else {
            msg = '手机号码长度不为11位';
        }
    } else {
        msg = '手机号码不能为空';
    }
    list.push(result);
    list.push(msg);
    return list;
}

// 转换时间
export function moment_set(e) {
    if (e) {
        return moment(e).format('YYYY-MM-DD HH:mm:ss');
    } else {
        return '';
    }
}
export function moment_date(e) {
    if (e) {
        return moment(e).format('YYYY-MM-DD');
    } else {
        return '';
    }
}

export function time_range_disp(begin, end) {
    let t1 = moment(begin);
    let t2 = moment(end);
    let disp = '';
    if (t1.isSame(t2, 'date')) {
        disp = t1.format('YYYY年M月D日 HH:mm') + '~' + t2.format('HH:mm');
    } else if (t1.isSame(t2, 'year')) {
        disp = t1.format('YYYY年M月D日') + '~' + t2.format('M月D日');
    } else {
        disp = t1.format('YYYY年M月D日') + '~' + t2.format('YYYY年M月D日');
    }
    return disp;
}

export function set_date(e) {
    const end = new Date();
    const start = new Date();
    switch (e) {
        case 1:
            // 获取当天时间0点0分
            return [new Date(new Date(new Date().toLocaleDateString()).getTime()), end];
        case 2:
            // 昨天时间
            return [
                new Date(new Date().toLocaleDateString()).getTime() - 3600 * 1000 * 24,
                new Date(new Date(new Date().toLocaleDateString()).getTime()),
            ];
        case 3:
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            return [start, end];

        case 4:
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
        default:
            return '';
    }
}

export function enterprise_state(e) {
    switch (e) {
        case 1:
            return '未认证';
        case 2:
            return '认证中';
        case 3:
            return '认证驳回';
        case 4:
            return '已认证';
        case 5:
            return '重认证中';
        case 6:
            return '重认证驳回';
    }
}

export function moment_isSame(start, end) {
    if (moment(moment(start).format('YYYY-MM-DD')).isSame(moment(end).format('YYYY-MM-DD'))) {
        return moment(end).format('HH:mm:ss');
    } else if (moment(moment(start).format('YYYY')).isSame(moment(end).format('YYYY'))) {
        return moment(end).format('MM-DD HH:mm:ss');
    } else {
        return false;
    }
}

export function limitFloat(val) {
    let sNum = val.toString(); //先转换成字符串类型
    if (sNum.indexOf('.') == 0) {
        //第一位就是 .
        console.log('first str is .');
        sNum = '0' + sNum;
    }
    sNum = sNum.replace(/[^\d.]/g, ''); //清除“数字”和“.”以外的字符
    sNum = sNum.replace(/\.{2,}/g, '.'); //只保留第一个. 清除多余的
    sNum = sNum
        .replace('.', '$#$')
        .replace(/\./g, '')
        .replace('$#$', '.');
    sNum = sNum.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); //只能输入两个小数
    //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
    if (sNum.indexOf('.') < 0 && sNum != '') {
        sNum = parseFloat(sNum);
    }
    console(sNum);
    return sNum;
}

// 七牛上传插件
import * as qiniu from 'qiniu-js';
export const upload = (token, obj, next, error, complete) => {
    const { file, user = {} } = obj;
    const putExtra = {
        fname: '', //上传文件名
        customVars: {
            //上传参数
            ['x:openid']: '' + (user.openid || user.userid),
            ['x:type']: '' + obj.type, // 需转换成字符串格式
            ['x:userid']: '' + (user.userid || ''),
        },
        mimeType: null,
    };
    let index = file.name.lastIndexOf('.');
    let key = file.name.substr(0, index) + '-' + Date.now() + file.name.substr(index); //文件名
    let config = {
        useCdnDomain: true, //是否加速
        region: qiniu.region.z2, //自动分析上传区域
    };
    let observable = qiniu.upload(file, key, token, putExtra, config);
    let subscription = observable.subscribe(next, error, complete);
    return subscription;
};
