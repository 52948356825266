import Vue from 'vue';
import Vuex from 'vuex';
import common from './modules/common';
import user_info from './modules/user_info';
import system from './modules/system';
import getters from './getters';
Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    common,
    user_info,
    system
  },
  getters
});

export default store;
