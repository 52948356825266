const getters = {
  user: state => state.user_info.user,
  isBatch: state => state.user_info.isBatch,
  isCooperationCustomer: state => state.user_info.isCooperationCustomer,
  priceFirst: state => state.user_info.priceFirst,
  msg: state => state.common.msg,
  browserHeaderTitle: state => state.common.browserHeaderTitle,
  enterprise: state => state.common.enterprise,
  firm_list: state => state.common.firm_list,
  systemInfo: state => state.system.info
};
export default getters;
