import router from '@/router'
import store from '@/store/index'
import NProgress from 'nprogress' // Progress 进度条
import 'nprogress/nprogress.css' // Progress 进度条样式
import * as api from './interface'
import { setTitle } from '@/utils/util'
import MtaH5 from '../utils/MtaH5'
/**
 * 免登录白名单
 */
const allow = ['/train', '/train/', '/pdf', '/']

router.beforeEach(async (to, from, next) => {
  try {
    const browserHeaderTitle = to.name
    if (!store.getters.priceFirst) {
      const { data } = await api.getFirst()
      store.commit('setFirst', data)

    }
    store.commit('set_title', browserHeaderTitle)

    // 如果没有系统信息，则先取系统信息
    if (!store.getters.systemInfo) {
      const {data} = await api.systemInfo()
      store.commit('setInfo', data)
    }

    if (!store.getters.user) {
      const { errcode, data } = await api.users()
      if (errcode === 0) {
        store.commit('set_user', data)
        localStorage.removeItem("sCode");
        const localUser = JSON.parse(localStorage.getItem('user'))
        if (localUser && localUser.userid != data.userid) localStorage.clear()
        if (to.path === '/') router.replace('/index')
      } else if (!allow.includes(to.path)) {
        router.replace('/')
      }
    }

    if (['/apply_firm?id=1', '/company'].includes(to.fullPath)) {
      const { data } = await api.companies_get()
      if (to.fullPath === '/company') {
        await store.commit('set_enterprise', data)
      } else {
        await store.commit('set_firm_list', data)
      }
    }
    setTimeout(next);
  } catch (error) {
    console.log(`拦截时 - ${error.message}：`, error)
    next()
  }
})

router.afterEach(to => {
  MtaH5.pgv()
  NProgress.done()
  setTimeout(() => {
    const browserHeaderTitle = store.getters.browserHeaderTitle
    setTitle(browserHeaderTitle)
  }, 0)
})
