const system = {
  state: {
    info: null
  },
  actions: {},
  mutations: {
    setInfo(state, info) {
      state.info = Object.assign({}, info);
    }
  }
};

export default system;
