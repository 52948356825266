<template>
  <div class="drawer_wrap" :style="show ? 'width: 300px;' : ''">
    <div class="drawer_icon">
      <el-badge is-dot type="primary" v-if="!show">
        <el-button
          @click="show = true"
          :style="show ? '' : 'margin-left: -15px'"
          type="danger"
          icon="el-icon-picture"
        ></el-button>
      </el-badge>
      <el-button
        type="info"
        @click="show = false"
        v-else
        icon="el-icon-error"
      ></el-button>
    </div>
    <div class="drawer_content" v-show="show">
      <div class="text_wrap">
        <div class="up_img tac">
          <img :src="imageUrl" alt srcset v-preview="imageUrl" />
        </div>
        <div class="up_btn tac">
          <el-radio v-model="radio" label="1">营业执照</el-radio>
          <el-radio v-model="radio" label="2">其他类型</el-radio>
          <el-upload
            style="margin-top: 5px"
            action="http://upload-z2.qiniup.com/"
            accept="image/jpeg, image/jpg, image/png, image/bmp"
            :data="uploadData"
            :show-file-list="false"
            element-loading-text="拼命识别中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <el-button
              style="color: #000"
              v-if="imageUrl"
              size="small"
              type="success"
              >重新上传</el-button
            >
            <el-button style="color: #000" v-else size="small" type="primary"
              >点击上传</el-button
            >
          </el-upload>
        </div>
        <div class="text_list">
          <div class="table_box">
            <el-table :data="tableData" style="width: 100%" v-loading="loading">
              <el-table-column fixed prop="words" label="识别结果">
                <template slot-scope="scope">
                  <div>{{ scope.row.title }}:{{ scope.row.words }}</div>
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="操作" width="50">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="small"
                    v-clipboard:copy="scope.row.words"
                    v-clipboard:success="() => $notify({ title: '复制成功' })"
                    >复制</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <div style="margin-top: 80px"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false,
      imageUrl: "",
      tableData: [],
      uploadData: { token: "", type: 4 },
      loading: false,
      radio: "2",
    };
  },
  methods: {
    handleAvatarSuccess(res, file) {
      this.imageUrl = res.url;
      this.loading = true;
      this.$api
        .ocr({ file: res.url, type: this.radio === "1" ? 1 : 4 })
        .then((result) => {
          this.loading = false;
          this.tableData = [];
          Object.entries(result.data.words_result).forEach(([key, value]) => {
            this.tableData.push({
              title: key,
              words: value.words,
            });
          });
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    async beforeAvatarUpload(file) {
      const isLt4M = file.size / 1024 / 1024 < 4;
      if (!isLt4M) {
        this.$message.error("上传图片大小不能超过 4MB!");
      }
      const { data } = await this.$api.upload_get({
        type: this.uploadData.type,
      });
      this.uploadData.token = data.token;
      return isLt4M;
    },
  },
};
</script>

<style lang="scss">
.drawer_wrap {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 0;
  z-index: 999;
  background: #ffffff;
  transition: width ease-in 0.35s;
  .drawer_icon {
    position: absolute;
    top: 48%;
    left: -55px;
  }
  .drawer_content {
    padding: 15px;
    .text_wrap {
      position: relative;
      bottom: 0;
      top: 0;
      height: 100vh;
      .up_img {
        width: 178px;
        height: 178px;
        display: block;
        text-align: center;
        margin: auto;
        padding: 3px;
        border: 2px solid #f5f5f5;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .tal {
        text-align: left;
      }
      .text_list {
        top: 250px;
        // top: 50%;
        bottom: 0;
        width: 100%;
        position: absolute;
        .table_box {
          height: 100%;
          overflow-y: scroll;
        }
      }
    }
  }
}
</style>
