<template>
  <div class="layout_wrap">
    <Navbar />
    <tags-view @nav_state="nav_state" />
    <div class="app_body" :class="main_left?'body_right':'body_left'">
      <app-main />
    </div>
    <Drawer v-if="look" />
  </div>
</template>

<script>
import { Navbar, AppMain, TagsView, Drawer } from "./components/index";
export default {
  components: {
    Navbar,
    AppMain,
    TagsView,
    Drawer
  },

  data() {
    return {
      main_left: false,
      look: false
    };
  },
  mounted() {
    if (process.env.id === 1) {
      this.look = true;
    }
  },
  methods: {
    nav_state(e) {
      this.main_left = e;
    }
  }
};
</script>

<style lang="scss" scoped>
.layout_wrap {
  height: 100%;
  .app_body {
    position: fixed;
    left: 0;
    right: 0;
    top: 65px;
    bottom: 0;
    z-index: 998;
    width: auto;
    overflow: hidden;
    overflow-y: auto;
    box-sizing: border-box;
    overflow-x: auto;
  }
  .body_left {
    margin-left: 64px;
    -webkit-transition: margin-left 0.3s;
    transition: margin-left 0.3s;
  }
  .body_right {
    margin-left: 164px;
    -webkit-transition: margin-left 0.35s;
    transition: margin-left 0.35s;
  }
}
</style>
