<template>
  <section class="app-main">
    <transition name="fade" mode="out-in">
      <router-view :key="key"></router-view>
    </transition>
  </section>
</template>

<script>
export default {
  name: "AppMain",
  data() {
    return {};
  },
  computed: {
    cachedViews(e) {},
    key() {
      this.$store.commit("set_route", this.$route);
      return this.$route.fullPath;
    },
  },
};
</script>

<style lang="scss" scoped>
.app-main {
  // min-height: 100%;
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 20px;
}
</style>
