import { moment_set } from '@/assets/js/validate';

const common = {
  state: {
    route_arr: {
      name: '首页',
      meta: {
        icon: 'iconzhuye'
      }
    },
    // 是否锁定
    apply: false,
    // 企业证件
    firm_img: {
      yy: '',
      zb: '',
      fb: '',
      pz: '',
      sp: ''
    },
    // 锁定发票信息
    lock: false,
    browserHeaderTitle: '士海',
    // 企业信息
    enterprise: '',
    msg: {
      num: 0,
      list: []
    },
    firm_list: ''
  },
  mutations: {
    set_route(state, name) {
      state.route_arr = name;
    },
    set_apply(state) {
      state.apply = !state.apply;
    },
    set_firm(state, info) {
      switch (info.id) {
        case 0:
          // 营业执照
          if (info.url) {
            state.firm_img.yy = info.url;
          } else {
            state.firm_img.yy = '';
          }
          break;
        case 1:
          // 正本
          state.firm_img.zb = info.url;
          break;
        case 2:
          // 副本
          state.firm_img.fb = info.url;
          break;
        case 3:
          // 品种明细
          state.firm_img.pz = info.url;
          break;
        case 4:
          // 食品经营许可
          state.firm_img.sp = info.url;
          break;
      }
    },
    set_bill(state, info) {
      state.lock = info || !state.lock;
    },
    // 设置标题
    set_title(state, title) {
      state.browserHeaderTitle = title || '士海';
    },
    // 企业信息
    set_enterprise(state, info) {
      try {
        info.manager.createAt = moment_set(info.manager.createAt);
      } catch (error) {
        console.log('企业信息转换时间出错');
      }
      state.enterprise = info || '';
    },
    set_msg(state, info) {
      state.msg.list = info || 0;
      if (info) {
        let num = 0;
        // num统计未读数量
        state.msg.list.forEach(e => {
          if (!e.isUserRead) {
            ++num;
            e.createAt = moment_set(e.createAt);
          }
        });
        state.msg.num = num;
      }
    },
    set_firm_list(state, list) {
      state.firm_list = list;
    }
  },
  actions: {},
  getters: {
    //对count的属性进行操作，只要count有任何变化，结果都会+100
    set_route(state) {
      return state.route_arr;
    }
  }
};
export default common;
