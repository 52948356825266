import Vue from 'vue'
import moment from "moment";
import App from './App'
import 'babel-polyfill' // 转成es5
import router from './router'
import store from './store'


import accounting from 'accounting'

Vue.filter('currency', value => accounting.formatMoney(value, '￥', 2))
Vue.filter('amount', value => accounting.formatMoney(value, '', 2))

// 优惠券活动
Vue.prototype.couponsTimeRange = (start, end) => {
  return moment(start).format('YYYY-MM-DD') + ' - ' + moment(end).format('YYYY-MM-DD')
}

Vue.prototype.couponsUserType = (type) => {
  return {
    0: '所有用户可用',
    1: '仅限普通用户',
    2: '仅限协议VIP用户',
  }[type]
}

// 仅剩天数少于7天，认为快过期了
Vue.prototype.isExpire = (time, day = 7) => moment(time).diff(moment(), 'day') < day


// 路由跳转判断
import '@/http/permission'

// 生成支付二维码
import QRCode from 'qrcodejs2'
const QRCodeSC = url => {
  let qrcode = new QRCode('qrcode', {
    width: 200, // 长度
    height: 200, // 宽度
    text: url // 内容
  })
  return qrcode
}

Vue.prototype.$QRCodeSC = QRCodeSC
import MtaH5 from './utils/MtaH5'
Vue.prototype.MtaH5 = MtaH5
import preview from 'vue-picture-preview'
import VueClipboard from 'vue-clipboard2'
import ElementUI from 'element-ui'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import * as api from './http/interface'
import 'swiper/css/swiper.css'
import 'element-ui/lib/theme-chalk/index.css';
import "element-ui/lib/theme-chalk/display.css";
import './assets/element-variables.scss'
import './assets/font.scss'
import './assets/common.scss'
import "@/filter/index";

Vue.use(VueAwesomeSwiper)
Vue.use(VueClipboard)
Vue.use(ElementUI)
Vue.use(preview)
// 请求接口

Vue.prototype.$api = api
Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  store,
  mounted() {
    // 延迟是为了等待页面渲染完成。
    setTimeout(() => {
      document.dispatchEvent(new Event('render-event'))
    }, 5000);
  },
  render: h => h(App)
})
