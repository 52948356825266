import axios from "./api";

// 登录扫码
export const weixinconfig = () => {
  return axios({
    url: "/api/login/weixinconfig",
    method: "get",
  });
};
// 注册扫码
export const weixinconfig_reg = () => {
  return axios({
    url: "/api/register/weixinconfig",
    method: "get",
  });
};

export const manager = () => {
  return axios({
    url: "/api/admin/manager",
    method: "get",
  });
};

// 文件上传
export const test = (data) => {
  return axios({
    url: "/api/upload",
    method: "post",
    data,
  });
};
// 手机号登录
export const mobile = (data) => {
  return axios({
    url: "/api/login/mobile",
    method: "post",
    data,
  });
};
// 获取用户信息
export const users = () => {
  return axios({
    url: "/api/users",
    method: "get",
  });
};
// 企业认证信息提交
export const companies = (data) => {
  return axios({
    url: "/api/companies",
    method: "post",
    data,
  });
};

export const companies_put = (data) => {
  return axios({
    url: "/api/companies",
    method: "put",
    data,
  });
};

export const companies_get = () => {
  return axios({
    url: "/api/companies",
    method: "get",
  });
};

// 食品标签
export const orders = (data) => {
  return axios({
    url: "/api/orders",
    method: "get",
    params: {
      ...data,
    },
  });
};
// 标签详情
export const orders_get = (data) => {
  return axios({
    url: `/api/orders/${data}`,
    method: "get",
  });
};

// 提交食品标签订单
export const orders_post = (data, params) => {
  return axios({
    url: `/api/orders`,
    method: "post",
    data,
    params,
  });
};

// 撤销订单
export const remove_orders = (data) => {
  return axios({
    url: `/api/orders/${data.tid}`,
    method: "post",
    data,
  });
};

export const payment = (params) => {
  return axios({
    url: `/api/payment`,
    method: "get",
    params,
  });
};

export const payment_post = (data) => {
  return axios({
    url: `/api/payment`,
    method: "post",
    data,
  });
};

// 订单追加支付cma报告
export const payment_cma = (data) => {
  return axios({
    url: `/api/payment/cma`,
    method: "post",
    data,
  });
};

// 下载cma报告
export const download_cma = (rid) => {
  return axios({
    url: `/api/cmareports/${rid}`,
    method: "post",
    responseType: 'blob',
  });
};

// 获取账户信息
export const account = (params) => {
  return axios({
    url: `/api/company/account`,
    method: "get",
    params,
  });
};
//  付款流水
export const payments = (params) => {
  return axios({
    url: `/api/company/payments`,
    method: "get",
    params,
  });
};
// 消费明细
export const orders_money = (params) => {
  return axios({
    url: `/api/company/orders`,
    method: "get",
    params,
  });
};

// 获取充值活动
export const promo = (params) => {
  return axios({
    url: `/api/company/promo`,
    method: "get",
    params,
  });
};
// 立即充值
export const account_post = (data) => {
  return axios({
    url: `/api/company/account`,
    method: "post",
    data,
  });
};

// 【发票】
// 发票列表
export const invoices = (params) => {
  return axios({
    url: `/api/invoices`,
    method: "get",
    params,
  });
};

// 获取开票信息
export const invoice = (params) => {
  return axios({
    url: `/api/invoice`,
    method: "get",
    params,
  });
};
// 修改发票信息
export const invoice_post = (data) => {
  return axios({
    url: `/api/invoice`,
    method: "post",
    data,
  });
};
export const payments_get = (data) => {
  return axios({
    url: `/api/payments`,
    method: "get",
    params: {
      data,
    },
  });
};
// 立即开票
export const invoices_post = (data) => {
  return axios({
    url: `/api/invoices`,
    method: "post",
    data,
  });
};

// 查询订单支付状态.
export const get_pay = (payId) => {
  return axios({
    url: `/api/payments/${payId}`,
    method: "get"
  });
};

// 注册登录
export const users_post = (data) => {
  return axios({
    url: `/api/users`,
    method: "post",
    data,
  });
};

// 注册验证码
export const mobilecode = (data) => {
  return axios({
    url: `/api/register/mobilecode`,
    method: "post",
    data,
  });
};
// 登录验证码
export const mobilecode_login = (data) => {
  return axios({
    url: `/api/login/mobilecode`,
    method: "post",
    data,
  });
};

// 用户动态
export const stats = (data) => {
  return axios({
    url: `/api/stats`,
    method: "get",
    data,
  });
};

export const users_put = () => {
  return axios({
    url: `/api/users`,
    method: "put",
  });
};

export const scanCode = (data) => {
  return axios({
    url: `/api/weixin/${data.scanCode}`,
    method: "get",
    params: {
      ...data,
    },
  });
};
export const messages = (data) => {
  return axios({
    url: `/api/messages`,
    method: "get",
    params: {
      ...data,
    },
  });
};
export const messages_post = (data) => {
  return axios({
    url: `/api/messages/${data.id}`,
    method: "post",
    data,
  });
};

// 就手机号
export const oldmobile_post = (data) => {
  return axios({
    url: `/api/user/oldmobile`,
    method: "post",
    data,
  });
};

// 新手机号
export const newmobile_post = (data) => {
  return axios({
    url: `/api/user/newmobile`,
    method: "post",
    data,
  });
};

export const oldmobile_put = (data) => {
  return axios({
    url: `/api/user/oldmobile`,
    method: "put",
    data,
  });
};

export const newmobile_put = (data) => {
  return axios({
    url: `/api/user/newmobile`,
    method: "put",
    data,
  });
};

// 获取微信配置
// 旧配置

export const oldweixinconfig = () => {
  return axios({
    url: `/api/user/oldweixinconfig`,
    method: "get",
  });
};

// 新配置
export const newweixinconfig = () => {
  return axios({
    url: `/api/user/newweixinconfig`,
    method: "get",
  });
};

// 获取支付验证码
export const mobile_post = () => {
  return axios({
    url: `/api/pay/mobile`,
    method: "post",
  });
};

export const mobile_put = (data) => {
  return axios({
    url: `/api/pay/mobile`,
    method: "put",
    data,
  });
};

// 同意协议勾选
export const protocol = () => {
  return axios({
    url: "/api/user/protocol",
    method: "post",
  });
};

// 七牛上传参数
export const upload_get = (params) => {
  return axios({
    url: "/api/upload",
    method: "get",
    params,
  });
};

// 账户管理列表
export const logs = (data) => {
  return axios({
    url: "/api/company/logs",
    method: "get",
    params: {
      ...data,
    },
  });
};

export const reports = (data) => {
  return axios({
    url: `/api/reports/${data.tid}`,
    method: "post",
    data,
  });
};
export const orders_put = (data) => {
  return axios({
    url: `/api/orders/${data.tid}`,
    method: "put",
    data,
  });
};
export const ocr = (data) => {
  return axios({
    url: `/api/ocr`,
    method: "post",
    data,
  });
};

export const allinvoiceinfos = () => {
  return axios({
    url: "/api/allinvoiceinfos",
    method: "get",
  });
};
export const invoiceinfos = (data) => {
  return axios({
    url: "/api/invoiceinfos",
    method: "post",
    data,
  });
};
export const post_invoiceinfos = (data) => {
  return axios({
    url: `/api/invoiceinfos/${data.id}`,
    method: "post",
    data,
  });
};
export const get_invoiceinfos = (id) => {
  return axios({
    url: `/api/invoiceinfos/${id}`,
    method: "get",
  });
};
export const delete_invoiceinfos = (id) => {
  return axios({
    url: `/api/invoiceinfos/${id}`,
    method: "DELETE",
  });
};

export function getFirst() {
  return axios({
    url: "/api/price/first",
    method: "get",
  });
}

export function getUnitPrice() {
  return axios({
    url: "/api/price",
    method: "get",
  });
}

export function pictureOrc(data) {
  return axios({
    url: `/api/admin/ocr`,
    method: "post",
    data,
  });
}

/**
 * 获取指定订单号列表导出excel报告的数据
 * @param {*} data {tradeIds: ["T20200323114343120233", ...]}
 * @returns 
 */
export function getExcelData(data) {
  return axios({
    url: `/api/excelreports`,
    method: "post",
    data,
  });
}

/** 获取分享码 */
export function getShareCode() {
  return axios('/api/getSharingCode')
}

export function exchangeCoupons(data) {
  return axios({
    url: '/api/coupons',
    data,
    method: 'PUT'
  })
}

/** 获取系统基本信息 */
export function systemInfo() {
  return axios({
    url: '/api/system/info',
    method: 'GET'
  })
}