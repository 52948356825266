import axios from 'axios';
import { Message } from 'element-ui';

const service = axios.create({
  // 开始ssr
  // baseURL: 'http://localhost:3001/'
});

// 请求前
service.interceptors.request.use(
  (config) => config,
  (error) => Promise.reject(error)
);

// 服务器返回结果
service.interceptors.response.use(
  (response) => {
    const data = response.data;
    if (Object.prototype.hasOwnProperty.call(data, 'errcode')) {
      if ([0, 1, 10001, 10, 100].includes(data.errcode)) return data;
      console.log('返回错误信息：', response);
      const errInfo = data?.errmsg || `status: ${response.status}`;
      Message({ type: 'error', message: errInfo });
      return Promise.reject(errInfo);
    } else {
      return response;
    }
  },
  (error) => {
    console.error('拦截错误：' + error);
    Message({ type: 'error', message: error.message });
    return Promise.reject(error);
  }
);

export default service;
