const user = {
  state: {
    user: '',
    isBatch: JSON.parse(localStorage.getItem('mode') || false),
    priceFirst: '',
    isCooperationCustomer: false,
  },
  actions: {

  },
  mutations: {
    setFirst(state, priceFirst) {
      state.priceFirst = priceFirst
    },
    set_user: (state, info) => {
      state.user = info
      if (state.user && state.user.company && state.user.company.cooperationStatus) {
        state.isCooperationCustomer = true
      }
    },
    set_mode(state, status) {
      localStorage.setItem('mode', status)
      state.isBatch = status
    }
  }
}

export default user
