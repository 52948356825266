import Vue from "vue";
import moment from "moment";

// 订单显示状态
const orderStatus = new Map([
    [1, '待付款'],
    [2, '待审核'],
    [4, '审核完成'],
    [6, '已关闭'],
    [7, '已退款'],
    [20, '待用户修改'],
    [21, '撤销重审中'],
    [31, '初审中'],
    [32, '复核中'],
    [50, '报告编制中'],
    [51, '报告编制中'],
    [52, '报告编制中'],
    [53, '报告编制中'],
    [54, '报告已签发']
])

Vue.filter('orderStatus', (status) => {
    if (!status) return ''
    return orderStatus.get(status) || '未知：' + status
})


Vue.filter('currency', value => accounting.formatMoney(value, '', 2))

Vue.filter('dateFormat', (date, isSimple) => {
    if (isSimple === true && date) return moment(date).format('YYYY/MM/DD')
    if (!date) return '-'
    return moment(date).format('YYYY-MM-DD HH:mm:ss')
})
